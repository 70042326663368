import { Component, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { NetworkService } from './services/network.service';
import { ServerVersionService } from './services/server-version.service';
import { SwUpdate } from '@angular/service-worker';
import { ApiService } from './services/api.service';
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @Select(UserState.getUser) user$: Observable<User>

  constructor(
    public networkService: NetworkService,
    private platform: Platform,
    public serverVersionService: ServerVersionService,
    private swUpdate: SwUpdate,
    private alertCtrl: AlertController,
    private apiService: ApiService,
    private store: Store
  ) {
    this.platform.ready().then(async () => {
      this.networkService.startWatching();
      this.serverVersionService.startWatching();
    });
    window.addEventListener('load', () => {
      window.history.pushState(null, null!, window.location.href);
    });

    window.addEventListener('popstate', () => {
      window.history.pushState(null, null!, window.location.href);
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.updatePrompt();
      });
    }

    this.networkService.events.subscribe((status: any) => {
      this.networkStatusChange();
    });
  }

  async networkStatusChange() {    
  }  

  updateConfirmed() {
    this.apiService.goHome();
  }

  updatePrompt() {
    this.alertCtrl.create({
      header: 'New Version Available',
      message: 'Click OK to update the software to the latest version',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.updateConfirmed();
          }
        }
      ]
    }).then(alert => alert.present());
  }
}
