import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  static setUser(user) {
    Bugsnag.setUser(user.id, user.email, user.fullname);
  }

  static log(error) {
    if (!error) {
      error = 'General app error occurred.';
    }
    Bugsnag.notify(error);
  }
}
