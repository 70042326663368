import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  loading: any;

  constructor(
      public loadingCtrl: LoadingController,
      private alertCtrl: AlertController,
      private toastCtrl: ToastController,
  ) { }

  async presentWaitMessage(msg: string = 'Please wait...') {
    const myloading = await this.loadingCtrl.create({
      message: msg
    });
    this.loading = myloading;
    return myloading.present();
  }

  dismissWaitMessage() {
    return this.loading.dismiss();
  }

  setWaitMessage(text: string) {
    const elem = document.querySelector(
        'div.loading-wrapper div.loading-content');
    if (elem) { elem.innerHTML = text; }
  }

  async showAlert(title, msg) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: ['OK']
    });
    return alert.present();
  }

  async showToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000
    });
    return toast.present();
  }
}
