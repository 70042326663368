import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import * as myGlobals from "../globals";
import { LoggerService } from "./logger.service";
import { ApisauceInstance, create } from "apisauce";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  url: string;
  timeout: number;
  apiClient: ApisauceInstance;
  api_endpoint: string;
  auth_endpoint: string;

  constructor(private platform: Platform) {
    this.url = this.getBaseApiUrl();
    this.timeout = myGlobals.app_api_timeout_ms;
    this.apiClient = create({
      baseURL: this.url,
      timeout: this.timeout,
    });
    this.api_endpoint = "apiv1/";
    this.auth_endpoint = "authv1/";
  }

  // checks if is object, null val returns false
  static isObject(val) {
    if (val === null) {
      return false;
    }
    return typeof val === "function" || typeof val === "object";
  }

  static getRetDataFromApiResults(api_results: any) {
    const resultObj: any = api_results;
    if (
      resultObj == null ||
      resultObj.retData == null ||
      (resultObj.error && resultObj.error.text)
    ) {
      if (resultObj && resultObj.error && resultObj.error.text) {
        LoggerService.log(resultObj.error.text);
      }
      return null;
    } else {
      return resultObj.retData;
    }
  }

  static sortByReverseUpdatedDate(aObj, bObj) {
    const a = moment(bObj.updated_at, "YYYY-M-D h:mm:ss a");
    const b = moment(aObj.updated_at, "YYYY-M-D h:mm:ss a");
    return a < b ? -1 : a > b ? 1 : 0;
  }

  static sortByUpdatedDate(aObj, bObj) {
    const a = moment(aObj.updated_at, "YYYY-M-D h:mm:ss a");
    const b = moment(bObj.updated_at, "YYYY-M-D h:mm:ss a");
    return a < b ? -1 : a > b ? 1 : 0;
  }

  getBaseApiUrl() {
    //return 'http://localhost:33015/'
    return environment.baseApiUrl;
  }

  getBaseAppUrl() {
    return window.location.origin + "/";
  }

  getStripeToken() {
    const liveKey =
      "pk_live_51I5uPICB44mDOUeUGwSl9muQ0j8QTXmyku4WD2eMX23A3l9RbYY1oeRENDKQk0kx2smiaSdMZbrgtnlpcOC9AQgi00m76QeWbh";
    const testKey =
      "pk_test_51I5uPICB44mDOUeU7t91dvWvEcHVX3zZJbPTKgi82cUIxfKpsshUrHb3WnuNYqowP4yUYeF7sL577m5bRGFdJENz00upW0EDOt";

    if (environment.env == "prod") {
      return liveKey;
    } else {
      return testKey;
    }
  }

  async isAccessible(userData) {
    const results = await this.apiReturnsAny("post", "appinfo", { userData });
    return results ? true : false;
  }

  async isAccessibleOrThrow(user_data) {
    if (!this.isAccessible(user_data)) {
      throw new Error("Server is not accessible");
    }
  }

  getRetDataFromApiResults(api_results: any) {
    const resultObj: any = api_results;
    if (
      resultObj == null ||
      resultObj.retData == null ||
      (resultObj.error && resultObj.error.text)
    ) {
      return null;
    } else {
      return resultObj.retData;
    }
  }

  private async getRequestConfig(
    method: string,
    url: string,
    params: any,
    content_type: string
  ) {
    const headers = { "Content-Type": content_type };

    if (method == "post") {
      return { method, url, data: JSON.stringify(params), headers };
    } else {
      return { method, url, params, headers };
    }
  }

  private async apiReturnsData(
    method: string,
    url: string,
    params: any,
    isAuth: boolean,
    content_type: string
  ) {
    let response: any;
    const endpoint = isAuth ? this.auth_endpoint : this.api_endpoint;

    const config: any = await this.getRequestConfig(
      method,
      endpoint + url,
      params,
      content_type
    );
    // Hack to force use of post so we get the automatic formatting of multipart form data.  We don't get that if using any for some reason.
    if (method == "post" && content_type == "multipart/form-data") {
      response = await this.apiClient.post(endpoint + url, params, config);
    } else {
      response = await this.apiClient.any(config);
    }

    if (response.ok && response.data && response.data.retData) {
      return response.data.retData;
    } else {
      let msg = "API error (will rethrow)";
      if (response && response.problem) {
        msg += ": " + response.problem;
      }
      LoggerService.log(msg);
      // return null;
      throw new Error("API Error");
    }
  }

  public async apiReturnsInt(
    method: string,
    url: string,
    params = {},
    isAuth = false,
    content_type = "application/x-www-form-urlencoded"
  ) {
    const retData = await this.apiReturnsData(
      method,
      url,
      params,
      isAuth,
      content_type
    );
    return retData ? parseInt(retData, 10) : 0;
  }

  public async apiReturnsAny(
    method: string,
    url: string,
    params = {},
    isAuth = false,
    content_type = "application/x-www-form-urlencoded"
  ) {
    const retData = await this.apiReturnsData(
      method,
      url,
      params,
      isAuth,
      content_type
    );
    return retData ? retData : null;
  }

  public async goHome() {
    window.location.href = this.getBaseAppUrl();
  }
}
