import { Injectable } from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppinfoService {

  endpoint: string;

  constructor(
      private apiService: ApiService
  ) {
    this.endpoint = 'appinfo';
  }

  public getAll(userData) {
    return this.apiService.apiReturnsAny('post',this.endpoint, { userData });
  }


}
