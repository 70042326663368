import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'auction',
    loadChildren: () => import('./pages/auction/auction.module').then(m => m.AuctionPageModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsPageModule)
  },
  {
    path: 'shared_auctions/:auction_id',
    loadChildren: () => import('./pages/shared-auctions/shared-auctions.module').then(m => m.SharedAuctionsPageModule)
  },
  {
    path: 'auction/:id',
    loadChildren: () => import('./pages/auction/auction.module').then( m => m.AuctionPageModule)
  },
  {
    path: 'lot/:auction_id',
    loadChildren: () => import('./pages/lot/lot.module').then(m => m.LotPageModule)
  },
  {
    path: 'lot/:auction_id/:id',
    loadChildren: () => import('./pages/lot/lot.module').then( m => m.LotPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'popover-menu-home',
    loadChildren: () => import('./pages/popover-menu-home/popover-menu-home.module').then(m => m.PopoverMenuHomePageModule)
  },
  {
    path: 'popover-menu-auction',
    loadChildren: () => import('./pages/popover-menu-auction/popover-menu-auction.module').then(m => m.PopoverMenuAuctionPageModule)
  },
  {
    path: 'popover-menu-lot',
    loadChildren: () => import('./pages/popover-menu-lot/popover-menu-lot.module').then(m => m.PopoverMenuLotPageModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetPageModule)
  },
  {
    path: 'register-confirm',
    loadChildren: () => import('./pages/register-confirm/register-confirm.module').then(m => m.RegisterConfirmPageModule)
  },
  {
    path: 'reset-confirm',
    loadChildren: () => import('./pages/reset-confirm/reset-confirm.module').then(m => m.ResetConfirmPageModule)
  },
  {
    path: 'popover-info-auction',
    loadChildren: () => import('./pages/popover-info-auction/popover-info-auction.module').then(m => m.PopoverInfoAuctionPageModule)
  },
  {
    path: 'discounts',
    loadChildren: () => import('./pages/discounts/discounts.module').then(m => m.DiscountsPageModule)
  },
  {
    path: 'export-auction-promo-pending/:id',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./pages/export-auction-promo-pending/export-auction-promo-pending.module').then(m => m.ExportAuctionPromoPendingPageModule)
  },
  {
    path: 'export-auction-promo-applied',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./pages/export-auction-promo-applied/export-auction-promo-applied.module').then(m => m.ExportAuctionPromoAppliedPageModule)
  },
  {
    path: 'export-auction-billing-address',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./pages/export-auction-billing-address/export-auction-billing-address.module').then(m => m.ExportAuctionBillingAddressPageModule)
  },
  {
    path: 'export-auction-payment',
    loadChildren: () => import('./pages/export-auction-payment/export-auction-payment.module').then(m => m.ExportAuctionPaymentPageModule)
  },
  {
    path: 'export-auction-export',
    loadChildren: () => import('./pages/export-auction-export/export-auction-export.module').then(m => m.ExportAuctionExportPageModule)
  },
  {
    path: 'discounts-add-edit',
    loadChildren: () => import('./pages/discounts-add-edit/discounts-add-edit.module').then(m => m.DiscountsAddEditPageModule)
  },
  {
    path: 'manage-auctions',
    loadChildren: () => import('./pages/manage-auctions/manage-auctions.module').then(m => m.ManageAuctionsPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
