import {UserLogin, UserToken } from "../models/user.model";

export namespace UserActions {
    export class Login {
        static readonly type = '[API-User] Login'
        constructor(public payload: UserLogin) {}
    }
    export class Logout {
        static readonly type = '[UI-User] Logout'
        constructor() {}
    }
    export class SetToken {
        static readonly type = '[API-User] Set Token'
        constructor(public payload: UserToken) {}
    }
}
