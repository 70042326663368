import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage
    ) {
    this.init()
  }

  async init() {   
    const storage = await this.storage.create()
    this._storage = storage
  }

  public async set(key: string, value: any) {
    try {
      await this._storage?.set(key, value)
    } catch (error) {
      LoggerService.log(error)
    }
  }

  public async get(key: string) {    
    try {
      const val = await this._storage?.get(key)
      return val
    } catch (error) {
      console.log(error)
      LoggerService.log(error)
    }
  }

  public async clear() {    
    try {
      await this._storage?.clear()
    } catch (error) {      
      LoggerService.log(error)
    }
  }

  public async remove(key: string) {
    try {
      await this._storage?.remove(key)
    } catch (error) {
      LoggerService.log(error)
    }
  }

  public async length() {
    try {
      return await this._storage?.length()
    } catch (error) {
      LoggerService.log(error)
      return 0
    }
  }


}
