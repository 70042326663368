import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import * as myGlobals from '../globals';
import * as moment from 'moment';
import {AlertController} from '@ionic/angular';
import {StorageService} from 'src/app/services/storage.service';
import {AppinfoService} from './appinfo.service';
import {UiService} from './ui.service';
import {LoggerService} from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class ServerVersionService {

  events: any;
  serverVersion;
  userData;
  page_strings: any;
  new_version_prompt_enabled = false;

  constructor(
      private alertCtrl: AlertController,
      private storage: StorageService,
      private appInfoService: AppinfoService,
      private uiService: UiService,
  ) {
    this.page_strings = {
      update_message_title: myGlobals.welcome_update_message_title,
      update_message: myGlobals.welcome_update_message,
      ok_button: myGlobals.app_ok_button
    };
    this.events = new Subject();
    this.serverVersion = 'Waiting...';
  }

  async startWatching() {
    // this.setVersionCheckTimer();
    await this.checkAppVersion();
  }

  setVersionCheckTimer() {
    setTimeout(async () => {
      await this.checkAppVersion();
    }, myGlobals.welcome_check_version_timeout_ms);
  }

  async checkAppVersion() {
    try {
        const val = await this.storage.get('userData');
        if (val) {
            const localAppVersion = this.getLocalAppVersion();
            const serverVersion = await this.getServerVersion(val);
            if (this.new_version_prompt_enabled) {
              if ((serverVersion !== 'UNKNOWN') && (localAppVersion !== serverVersion)) {
                this.promptToDownloadNewVersion();
              }
            }
        }
    } catch (e) {
        LoggerService.log(e);
    } finally {
        this.setVersionCheckTimer();
    }
  }

  getLocalAppVersion() {
    return myGlobals.app_version_number;
  }

  async getServerVersion(userData) {
    this.serverVersion = 'UNKNOWN';
    const api_results = await this.appInfoService.getAll(userData);
    this.serverVersion = api_results[0].app_version;
    // 'publish last_version_check'
    await this.events.next({lastCheck: moment().format('MMM DD YYYY, h:mm:ss a'), serverVersion: this.serverVersion});
    return this.serverVersion;
  }

  async promptToDownloadNewVersion() {
    const alert = await this.alertCtrl.create({
      header: this.page_strings.update_message_title,
      message: this.page_strings.update_message,
      backdropDismiss: false,
      buttons: [
        {
          text: this.page_strings.ok_button,
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });
    await alert.present();
  }
}
